html,
body,
#root,
.ant-spin-nested-loading,
.ant-spin-container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Fira Sans', sans-serif;
}

.webviewer {
  flex: 1 1;
  margin: 8px;
  box-shadow: 1px 1px 10px #999;
}

.App {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.App .header {
  width: 100%;
  overflow: auto;
  padding: 8px 8px 8px 5%;
  box-sizing: border-box;
  background: #002a5b;
  font-size: 1.2em;
  line-height: 44px;
  color: #ffffff;
}
/* 007cf9 */

.logo {
  height: 45px;
}
.select-signer {
  width: 400px;
  margin-left: 16px;
  margin-right: 16px;
}
.send-button {
  float: right;
  margin-right: 3%;
  margin-top: 8px;
}
.send-button.disable {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
  text-shadow: none;
  box-shadow: none;
}
.alert-bar {
  background-color: #f97300;
  color: #fff;
  font-weight: 300;
  padding: 8px 5%;
  width: 100%;
}
.alert-bar.complete {
  background-color: #1890ff;
}
.doc-title {
  margin-left: 16px;
  word-wrap: break-word;
}
.ant-btn-background-ghost {
  border-color: transparent !important;
}
.ant-btn-background-ghost:hover {
  border-color: white !important;
}
.text-small {
  display: block;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 10px;
}

textarea {
  border-radius: 5px !important;
}

.ant-switch-small {
  background-color: #adb5bd;
}

.ant-switch-checked {
  background-color: #166cbf;
}

.ant-modal-header {
  border-bottom: 0;
}

.ant-modal-footer {
  border-top: 0;
}

.modal-paragraph {
  font-size: .98em;
  margin-bottom: 30px;
}

.ant-btn {
  border-radius: 5px !important;
}

::-webkit-input-placeholder {
  font-size: 12px;
}

::placeholder {
  font-size: 12px;
}

.signature-toggle {
  float: right;
}

.signature-toggle span {
  margin-right: 5px;
}

.app-confirmation {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.app-confirmation img {
  height: 80px;
  width: auto;
}

.redirect-buttons {
  margin-top: 14px;
}

.redirect-buttons > button {
  display: block;
  background-color: #0074ff;
  color: #ffffff;
  font-size: 12px;
  height: auto;
  margin: 10px auto;
  padding: 8px 12px;
}

.redirect-buttons .link {
  background: none;
  box-shadow: none;
  border: none;
  color: #0074ff;
}

/* -------------------------------------
RESPONSIVE AND MOBILE FRIENDLY STYLES
------------------------------------- */

@media only screen and (max-width: 480px) {
  .App .header {
    padding: 6px 6px 2.5%;
    font-size: .8em;
  }

  .app-confirmation h1 {
    font-size: 32px;
  }

  .app-confirmation p {
    font-size: 12px;
  }

  .alert-bar {
    padding: 4px 2.5%;
    font-size: .8em;
  }

  .send-button {
    width: 100%;
    float: none;
    margin-right: 0;
    margin-top: 0;
  }
}

/* ---------------------
SIMPLEMDE CSS OVERRIDES
------------------------ */

.editor-toolbar {
  border-color: #ced4da !important;
  padding: 2px 5px !important;
}

.side-by-side,
.fullscreen {
  display: none !important;
}

.CodeMirror {
  border-color: #ced4da !important;
  font-size: 14px !important;
  height: 150px !important;
}

.CodeMirror-code {
  height: inherit !important;
}

.CodeMirror-sizer,
.CodeMirror-scroll {
  min-height: 140px !important;
}

