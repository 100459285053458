html,
body,
#root,
.ant-spin-nested-loading,
.ant-spin-container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Fira Sans', sans-serif;
}

.webviewer {
  flex: 1;
  margin: 8px;
  -webkit-box-shadow: 1px 1px 10px #999;
  box-shadow: 1px 1px 10px #999;
}
